import React from 'react';
import { approveRegistrationRequestAPI, restoreRegistrationRequestAPI } from 'services/registrationRequestsApi';
import { CustomTooltip } from 'components/CustomTooltip';
import { RegistrationRequest, RegistrationRequestApproveRestoreResponse } from 'models/RegistrationRequest';
import { toastSuccess } from 'utils/toastMethod';
import acceptIcon from 'assets/images/accept.svg';
import rejectIcon from 'assets/images/reject.svg';
import restoreIcon from 'assets/images/restore.svg';

interface RowActionsProps {
  registrationRequest: RegistrationRequest;
  registrationRequests: RegistrationRequest[] | undefined;
  setRegistrationRequests: (newValue: RegistrationRequest[]) => void;
}

export default function RegistrationRequestsTable({
  registrationRequest,
  registrationRequests,
  setRegistrationRequests,
}: RowActionsProps) {
  const approveRegistrationRequest = async (isApproved: boolean) => {
    approveRegistrationRequestAPI(registrationRequest.id, { isApproved: isApproved })
      .then((response) => {
        const newRegistrationRequests = registrationRequests?.map((i) => {
          if (i.id === registrationRequest.id)
            return { ...i, status: response !== undefined ? response.status : 'PENDING' };
          else return i;
        });
        if (newRegistrationRequests !== undefined) setRegistrationRequests(newRegistrationRequests);
      })
      .catch((error) => console.log(error));
  };
  const restoreRegistrationRequest = () => {
    restoreRegistrationRequestAPI(registrationRequest.id)
      .then((response: RegistrationRequestApproveRestoreResponse) => {
        toastSuccess('Reset eseguito correttamente.');
        const newRegistrationRequests = registrationRequests?.map((i) => {
          if (i.id === registrationRequest.id)
            return { ...i, status: response !== undefined ? response.status : 'PENDING' };
          else return i;
        });
        if (newRegistrationRequests !== undefined) setRegistrationRequests(newRegistrationRequests);
      })
      .catch((error) => console.log(error));
  };

  return registrationRequest.status === 'PENDING' ? (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ cursor: 'pointer' }} onClick={() => approveRegistrationRequest(true)}>
        <CustomTooltip id="AcceptIcon" message="Accetta richiesta">
          <img src={acceptIcon} alt="acceptIcon" />
        </CustomTooltip>
      </div>
      <div style={{ cursor: 'pointer' }} onClick={() => approveRegistrationRequest(false)}>
        <CustomTooltip id="RejectIcon" message="Rifiuta richiesta">
          <img src={rejectIcon} alt="rejectIcon" />
        </CustomTooltip>
      </div>
    </div>
  ) : registrationRequest.status === 'REJECTED' ? (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ cursor: 'pointer' }} onClick={() => restoreRegistrationRequest()}>
        <CustomTooltip id="RestoreIcon" message="Resetta stato richiesta">
          <img src={restoreIcon} alt="restoreIcon" />
        </CustomTooltip>
      </div>
    </div>
  ) : null;
}
