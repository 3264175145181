import React from 'react';
import { CustomTooltip } from 'components/CustomTooltip';
import dangerIcon from '../../../assets/images/danger-triangle.svg';
import './style.css';

interface DuplicateActivityNameProps {
  name?: string;
  tooltipMessage?: string;
  backgroundColor?: string;
}

export const DuplicateActivityName = ({ name, tooltipMessage, backgroundColor }: DuplicateActivityNameProps) => {
  return (
    <div className="duplicate-activity" style={{ backgroundColor }}>
      <>{name}</>
      <div style={{ marginLeft: 'auto' }}>
        <CustomTooltip id="dangerTriangle" message={tooltipMessage ?? ''}>
          <img src={dangerIcon} style={{ marginLeft: '10px' }} alt="dangerTriangle" />
        </CustomTooltip>
      </div>
    </div>
  );
};
